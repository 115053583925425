
const utils = {
  version: 1,
  privvaVersion: "9.5.2",
  development: false,
  risk_rating_colors: {"81":"#01BC31","61":"#8CC93D","41":"#FED260","21":"#EB5809","0":"#DD2400"},
  issue_resolutions: {"resolved":"Resolved","failed":"Failed","risk_accepted":"Risk Accepted","in_remediation":"In Remediation","canceled":"Canceled"},
  issue_states: {"created":"Created","opened":"Open","responded":"Responded","closed":"Closed","archived":"Archived"},
  assessment_grade_options: [{"value":-1,"label":"Fail","color":"#DD2400"},{"value":0,"label":"None","color":"#666666"},{"value":1,"label":"Pass","color":"#01BC31"}],
  RiskLabelSets: {"standard":{"id":1,"name":"Standard","rating_label":"Risk Rating","score_labels":{"-2":"Very Poor","-1":"Poor","0":"Acceptable","1":"Good","2":"Very Good"}},"due_diligence":{"id":2,"name":"Due Diligence","rating_label":"Score","score_labels":{"-2":"Highly Unsatisfactory","-1":"Somewhat Unsatisfactory","0":"Neutral","1":"Somewhat Satisfactory","2":"Highly Satisfactory"}},"satisfaction":{"id":3,"name":"Satisfaction","rating_label":"Satisfaction Score","score_labels":{"-2":"Extremely Dissatisfied","-1":"Somewhat Dissatisfied","0":"Neither Satisfied nor Dissatisfied","1":"Somewhat Satisfied","2":"Highly Satisfactory"}},"performance_review":{"id":4,"name":"Performance Review","rating_label":"Performance Rating","score_labels":{"-2":"Far Below Expectations","-1":"Below Expectations","0":"Meets Expectations","1":"Exceeds Expectations","2":"Far Exceeds Expectations"}},"numeric":{"id":5,"name":"Numeric","rating_label":"Score","score_labels":{"-2":"1","-1":"2","0":"3","1":"4","2":"5"}},"low_to_high":{"id":6,"name":"Low to High","rating_label":"Score","score_labels":{"-2":"Very Low","-1":"Low","0":"Medium","1":"High","2":"Very High"}},"risk_score":{"id":7,"name":"Risk Score","rating_label":"Risk Score","score_labels":{"-2":"Very High","-1":"High","0":"Acceptable","1":"Low","2":"Very Low"}},"requirements_evaluation":{"id":8,"name":"Requirements Evaluation","rating_label":"Readiness","score_labels":{"-2":"Does not meet requirements","-1":"Minimally meets requirements","0":"Moderately meets requirements","1":"Mostly meets requirements","2":"Meets requirements"}},"capability_maturity_model":{"id":9,"name":"Capability Maturity Model","rating_label":"Maturity","score_labels":{"-3":"Non-Existent","-2":"Initial / Ad-hoc","-1":"Repeatable but Intuitive","0":"Defined","1":"Measured and Managed","2":"Optimized"}}},
  UserOrgRoles: {"owner":{"value":"owner","label":"Client Success","org_option":false,"default_invite_option":false,"vendor_option":false,"allows_vendors":false,"sort":7},"admin":{"value":"admin","label":"Admin","org_option":true,"default_invite_option":false,"vendor_option":false,"allows_vendors":false,"sort":6},"manage":{"value":"manage","label":"Manager","org_option":true,"default_invite_option":true,"vendor_option":false,"allows_vendors":false,"sort":5},"user":{"value":"user","label":"User","org_option":true,"default_invite_option":false,"vendor_option":false,"allows_vendors":false,"sort":4},"read_only":{"value":"read_only","label":"Read Only","org_option":true,"default_invite_option":false,"vendor_option":false,"allows_vendors":false,"sort":3},"summary":{"value":"summary","label":"Summmary View","org_option":false,"default_invite_option":false,"vendor_option":false,"allows_vendors":false,"sort":2},"status_only":{"value":"status_only","label":"Status Only","org_option":true,"default_invite_option":false,"vendor_option":true,"allows_vendors":true,"sort":1},"none":{"value":"none","label":"None","org_option":false,"default_invite_option":false,"vendor_option":false,"allows_vendors":false,"sort":0}},
  promotionTypes: {"MODAL_VIDEO":"MODAL_VIDEO","BASIC_MESSAGE":"BASIC_MESSAGE"},
  promotionPlacements: {"VENDOR_ASSESSMENT_SUBMIT":"VENDOR_ASSESSMENT_SUBMIT","RISK_RECON_DETAILS":"RISK_RECON_DETAILS"},

  OfacMatchLabel: 'Sanctions',
  OfacNoMatchLabel: 'No Sanctions',
  OfacNoInfoLabel: 'Not Enough Info',

  endpoints: {
    clientAssessments: "/api/client/assessments",
    clientAssessmentsSearch: "/api/client/assessments/search",
    clientAssessment: "/api/client/assessments/:id",
    clientAssessmentAttachments: "/api/client/assessments/:id/attachments_list",
    clientAssessmentAttachmentsZip: "/api/client/assessments/:id/attachments_zip",
    clientAssessmentElements: "/api/client/assessments/:id/elements",
    clientAssessmentResponseImport: "/api/client/assessments/:assessmentId/response_import",
    clientAssessmentForceSubmit: "/api/client/assessments/:id/force_submit",
    clientAssessmentUndoSubmit: "/api/client/assessments/:id/undo_submit",
    clientAssessmentContinueReview: "/api/client/assessments/:id/continue_review",
    clientAssessmentCompleteReview: "/api/client/assessments/:id/complete_review",
    clientAssessmentRiskScoreRating: "/api/client/assessments/:id/risk_score_summary",
    clientAssessmentSection: "/api/client/assessments/:assessmentId/sections/:sectionId",
    clientAssessmentSummary: "/api/client/assessment_summary",
    clientElement: "/api/client/elements/:id",
    clientElementCopySection: "/api/client/elements/:id/copy_section",
    clientChoice: "/api/client/choices/:id",
    clientAssessmentTemplates: "/api/client/assessment_templates",
    clientAssessmentTemplate: "/api/client/assessment_templates/:id",
    clientAssessmentTemplateDuplicate: "/api/client/assessment_templates/:id/duplicate",
    clientAssessmentTemplateArchive: "/api/client/assessment_templates/:id/archive",
    clientAssessmentTemplateMakeReady: "/api/client/assessment_templates/:id/make_ready",
    clientAssessmentTemplateUnready: "/api/client/assessment_templates/:id/unready",
    clientAssessmentTemplateElements: "/api/client/assessment_templates/:id/elements",
    clientAssessmentTemplateElementsMultiCreate: "/api/client/assessment_templates/:id/elements/multi_create",
    clientAssessmentTemplateElementsMultiCopy: "/api/client/assessment_templates/:id/elements/multi_copy",
    clientAssessmentTemplateElementsMove: "/api/client/assessment_templates/:id/elements/move",
    clientAssessmentTemplateElementsMultiUpdate: "/api/client/assessment_templates/:id/elements/multi_update",
    clientAssessmentTemplateElementsMultiDelete: "/api/client/assessment_templates/:id/elements/multi_delete",
    clientAssessmentTemplateAttachmentsCheck: "/api/client/assessment_templates/:id/check_attachment_presence",
    clientCommentThreads: "/api/client/comment_threads",
    clientCommentThreadsFind: "/api/client/comment_threads/find",
    clientCommentThread: "/api/client/comment_threads/:id",
    clientGlobalAssessmentTemplates: "/api/client/global_assessment_templates",
    clientGlobalAssessmentTemplatesAddFromLibrary: "/api/client/global_assessment_templates/:id/add_from_library",
    clientIssues: "/api/client/issues",
    clientIssue: "/api/client/issues/:id",
    clientIssueCategories: "/api/client/issue_categories",
    clientIssueCategory: "/api/client/issue_categories/:id",
    clientIssueSummary: "/api/client/issue_summary",
    clientIssueStub: "/api/client/issue_stubs/:id",
    clientIssueStubs: "/api/client/issue_stubs",
    clientClientSourceContract: "/api/client/client_source_contracts/:id",
    clientClientSourceContracts: "/api/client/client_source_contracts",
    clientQuestionAutomation: "/api/client/question_automations/:id",
    clientQuestionAutomations: "/api/client/question_automations",
    clientAssessmentQuestionAutomation: "/api/client/assessment_question_automations/:id",
    clientAssessmentQuestionAutomations: "/api/client/assessment_question_automations",
    clientGlobalProfilesSummary: "/api/client/globorg/orgs",
    clientProjects: "/api/client/projects",
    clientProjectsWithCounts: "/api/client/projects/with_counts",
    clientProjectNames: "/api/client/projects/names",
    clientProject: "/api/client/projects/:id",
    clientProjectNew: "/api/client/projects/new",
    clientProjectLaunch: "/api/client/projects/:id/launch",
    clientProjectRelaunch: "/api/client/projects/:id/relaunch",
    clientProjectClose: "/api/client/projects/:id/close",
    clientProjectArchive: "/api/client/projects/:id/archive",
    clientProjectUnarchive: "/api/client/projects/:id/unarchive",
    clientProjectReview: "/api/client/projects/:id/review",
    clientProjectProjectVendors: "/api/client/projects/:projectId/project_vendors",
    clientProjectMultiProjectVendors: "/api/client/projects/:id/project_vendors_multi",
    clientProjectTotals: "/api/client/projects/:id/totals",
    clientProjectVendor: "/api/client/project_vendors/:id",
    clientProjectVendorCancel: "/api/client/project_vendors/:id/cancel",
    clientProjectVendorLaunch: "/api/client/project_vendors/:id/launch",
    clientProjectVendorRelaunch: "/api/client/project_vendors/:id/relaunch",
    clientProjectVendorReinvite: "/api/client/project_vendors/:id/reinvite",
    clientProjectVendorApproveExtension: "/api/client/project_vendors/:id/approve_extension",
    clientProjectEventSubscriptions: "/api/client/projects/:projectId/event_subscriptions",
    clientProjectEventSubscriptionsAdd: "/api/client/projects/:projectId/event_subscriptions/add",
    clientProjectEventSubscriptionsUpdateSubscriber: "/api/client/projects/:projectId/event_subscriptions/update_subscriber",
    clientProjectEventSubscriptionsRemoveEventSubscriber: "/api/client/projects/:projectId/event_subscriptions/remove_subscriber",
    clientProjectEventSubscription: "/api/client/projects/:projectId/event_subscriptions/:id", // for now, subject to change
    clientRiskDomains:  "/api/client/risk_domains",
    clientVendors: "/api/client/vendors",
    clientVendor: "/api/client/vendors/:id",
    clientVendorFacetableSets: "/api/client/vendors/:id/facetable_sets",
    clientVendorProjects: "/api/client/vendors/:id/projects",
    clientVendorsPropose: "/api/client/vendor_requests",
    clientVendorsRequesters: "/api/client/vendor_requests/all_requesters",
    clientVendorRequestOpenReview: "/api/client/vendor_requests/:id/open_review",
    clientVendorRequestDecline: "/api/client/vendor_requests/:id/decline",
    clientVendorRequestApprove: "/api/client/vendor_requests/:id/approve",
    clientVendorResendUserInvite: "/api/client/vendors/:vendorId/resend_user_invite/:userId",
    clientVendorOrganizationDetail: "/api/client/vendors/:id/organization_detail",
    clientVendorVendorTags: "/api/client/vendors/:id/vendor_tags",
    clientVendorResultsSummary: "/api/client/vendors/:id/results_summary",
    clientVendorNames:  "/api/client/vendors/names",
    clientVendorsSummary: "/api/client/vendors/summary",
    clientVendorRequestsSummary: "/api/client/vendor_requests/summary",
    clientVendorsSimilar: "/api/client/vendors/similar",
    clientVendorsBulkImport: "/api/client/vendors/bulk_import",
    clientVendorsBulkCustomFieldImport: "/api/client/vendors/bulk_custom_field_import",
    clientVendorAttachments: "/api/client/client_vendor_attachments",
    clientAttachmentsForVendor: "/api/client/vendor/:id/attachments",
    clientVendorAttachment: "/api/client/client_vendor_attachments/:id",
    clientSourcedAttachments: "/api/client/client_sourced_attachments",
    clientVendorAttachmentUpload: "/api/client/client_vendor_attachments/:id/upload",
    clientVendorIntegrationLink: "/api/client/vendors/:id/integration_link",
    clientVendorAssessmentsStatus: "/api/client/vendors/vendor_assessments_status",
    clientVendorFacetSets: "/api/client/vendors/vendor_facet_sets",
    clientVendorOfacStatusReport: "/api/client/vendors/ofac_status",
    clientResponse: "/api/client/responses/:id",
    clientResponseIssues: "/api/client/responses/:id/issues",
    clientOrganizationPartners: "/api/client/organization_partners",
    clientOrganizationPartner: "/api/client/organization_partners/:id",
    clientOrganizationPartnerMapping: "/api/client/organization_partner_mappings/:id",
    clientOrganizationPartnerPartnerMapping: "/api/client/organization_partners/:partnerId/organization_partner_mappings",
    clientOrganizationGloborgMappings: "/api/client/organization_globorg_mappings",
    clientOrganizationGloborgMapping: "/api/client/organization_globorg_mappings/:id",
    clientOrganizationGloborgMappingConfirm: "/api/client/organization_globorg_mappings/:id/confirm",
    clientClientTags: "/api/client/client_tags",
    clientClientTag: "/api/client/client_tags/:id",
    clientUserGroups: "/api/client/user_groups",
    clientUserGroup: "/api/client/user_groups/:id",
    clientRiskTierCalculators: "/api/client/risk_tier_calculators",
    clientRiskTierCalculator: "/api/client/risk_tier_calculators/:id",
    clientAssessmentCycleTimeReport: "/api/client/assessment_cycle_time_report",
    clientAssessmentMonthlyMetrics: "/api/client/assessment_monthly_metrics",
    setActiveClientRiskTierCalculator: "/api/client/risk_tier_calculators/:id/set_active",
    clientCustomFieldDefs: "/api/client/custom_field_definitions",
    clientCustomFieldDef: "/api/client/custom_field_definitions/:id",
    sortClientCustomFieldDefs: "/api/client/custom_field_definitions/sort",
    clientCustomFieldGroup: "/api/client/custom_field_groups/:key",
    clientCustomFieldGroups: "/api/client/custom_field_groups",
    sortClientCustomFieldGroups: "/api/client/custom_field_groups/sort",
    deleteClientCustomFieldGroups: "/api/client/custom_field_groups/delete",
    clientVendorCustomFieldData: "/api/client/vendors/:id/custom_field_data",
    clientWatchlists: "/api/client/watchlists",
    clientWatchlist: "/api/client/watchlists/:id",
    clientWatchlistGloborgs: "/api/client/watchlist_globorgs",
    clientGloborgOrg: "/api/client/globorg/orgs/:id",
    clientSearchTerms: "/api/client/search/terms",
    vendorAssessments: "/api/vendor/assessments",
    vendorAssessment: "/api/vendor/assessments/:id",
    vendorAssessmentAutoAnswerable: "/api/vendor/assessments/:id/auto_answerable",
    vendorAssessmentAutoAnswer: "/api/vendor/assessments/:id/auto_answer",
    vendorAssessmentValidate: "/api/vendor/assessments/:id/validate",
    vendorAssessmentSection: "/api/vendor/assessments/:assessmentId/sections/:sectionId",
    vendorAssessmentElements: "/api/vendor/assessments/:id/elements",
    vendorAssessmentElementResponse: "/api/vendor/assessments/:assessmentId/elements/:elementId/response",
    vendorAssessmentResponseImport: "/api/vendor/assessments/:assessmentId/response_import",
    vendorIssues: "/api/vendor/issues",
    vendorIssue: "/api/vendor/issues/:id",
    vendorIssueTouch: "/api/vendor/issues/:id/touch",
    vendorProject:  "/api/vendor/projects/:id",
    vendorProjectAutomations: "/api/vendor/project_automations",
    vendorProjectAutomationRequest: "/api/vendor/project_automations/:id",
    vendorProjectRequestExtension: "/api/vendor/projects/:id/request_extension",
    vendorCommentThreads: "/api/vendor/comment_threads",
    vendorCommentThreadsFind: "/api/vendor/comment_threads/find",
    vendorSearchTerms: "/api/vendor/search/terms",
    assistAssessments: "/api/assist/assessments",
    assistAssessment: "/api/assist/assessments/:id",
    assistAnswerFiles: "/api/assist/answer_files",
    assistAnswerFile: "/api/assist/answer_files/:id",
    assistQuestionSets: "/api/assist/question_sets",
    assistQuestionSet: "/api/assist/question_sets/:id",
    assistQuestionSetStatus: "/api/assist/question_sets/:id/status",
    assistQuestionSetAnswerSets: "/api/assist/question_sets/:id/question_set_answer_sets",
    assistQuestionSetScorerTypes: "/api/assist/question_set_scorer/types",
    assistQuestionSetScorerModels: "/api/assist/question_set_scorer/models",
    assistAnswerSets: "/api/assist/answer_sets",
    assistAnswerSet: "/api/assist/answer_sets/:id",
    assistQuestion: "/api/assist/questions/:id",
    assistQuestionSearch: "/api/assist/questions/:id/search",
    assistQuestionAnswers: "/api/assist/question_answers",
    assistQuestionAnswer: "/api/assist/question_answers/:id",
    assistOrganizationsSelect: "/api/assist/organizations/select",
    industries: "/api/industry",
    organizationSSO: "/api/organization_sso/:organizationId",
    organizationClientCustomers: "/api/organizations/:organizationId/client_customers",
    organizations: "/api/organizations",
    announcements: "/api/announcements",
    announcementsDismissNow: "/api/announcements/dismiss_now",
    twoFactorAuths: "/api/two_factor_auths",
    twoFactorAuthsDisable: "/api/two_factor_auths/disable",
    currentUser: "/user",
    currentOrganization: "/organization",
    users: "/api/users",
    user: "/api/users/:id",
    userSetAPIEnabled: "/api/users/:id/set_api_enabled",
    userOrgRoleSet: "/api/users/:id/set_org_role",
    userInvitation: "/users/invitation.json",
    userPreferences: "/api/user_preferences",
    userRemoveFromOrganization: "/api/users/:id/remove_from_organization",
    userSubscriptions: "/users/:user_id/subscriptions",
    subscribeUserSubscription: "/users/:user_id/subscriptions/:id/subscribe.json",
    unsubscribeUserSubscription: "/users/:user_id/subscriptions/:id/unsubscribe.json",
    subscribeUserSubscriptionEmail: "/users/:user_id/subscriptions/:id/subscribe_to_email.json",
    unsubscribeUserSubscriptionEmail: "/users/:user_id/subscriptions/:id/unsubscribe_to_email.json",
    globorgAutocomplete: "/g/api/autocomplete",
    globorgOrg: "/g/api/orgs/:id",
    libraryManageProfile: "/lib/api/manage/profiles/:id",
    libraryManageListings: "/lib/api/manage/listings",
    libraryManageListing: "/lib/api/manage/listings/:id",
    libraryManageShares: "/lib/api/manage/shares",
    libraryManageShare: "/lib/api/manage/shares/:id",
    libraryManageShareRequests: "/lib/api/manage/shares/requests",
    libraryManageSharesByClient: "/lib/api/manage/shares/by_client",
    libraryManageSharesClientUpdate: "/lib/api/manage/shares/client_update",
    libraryProfiles: "/lib/api/profiles",
    libraryListings: "/lib/api/listings",
    libraryListingRequestShare: "/lib/api/listings/:id/request_share",
    libraryProfileVendor: "/lib/api/profiles/:id/vendor",
    libraryProfileConvert: "/lib/api/profiles/:id/convert",
    libraryShare: "/lib/api/shares/:id",
    globorgOrgIntegrationMetrics: "/g/api/orgs/:id/integration_metrics",
    globorgOrgIntegrationMetricsRiskReconIssues: "/g/api/orgs/:id/integration_metrics/risk_recon_issues",
    promotionsForPlacement: "/api/promotions/placement",
    promotionIncrementClickCount: "/api/promotions/:id/increment_click_count",
    promotionProcessCallToAction: "/api/promotions/:id/perform_actions",
    rumToken: "/api/rum/token",
    scopePermissions: "/api/permissions/scope"
  },
  pageUrls: {
    clientReports: "/c/reports",
    clientProjects: "/c/projects",
    clientProject: "/c/projects/:id",
    clientProjectEdit: "/c/projects/:id/edit",
    clientProjectResultsReport: "/c/projects/:id/results_report",
    clientProjectResultsExport: "/c/projects/:id/results_export",
    clientProjectResponseComparison: "/c/projects/:id/response_comparison",
    clientProjectGrade: "/c/projects/:id/grade",
    clientAssessmentTemplates: "/c/templates",
    clientAssessmentTemplate: "/c/templates/:id",
    clientAssessmentTemplateExcelExport: "/c/templates/:id/excel_export.:format",
    clientAssessmentTemplateAttachmentsZip: "/c/templates/:id/attachments_zip.:format",
    clientAssessment: "/c/assessments/:id",
    clientAssessmentCycleTimeReport: "/c/assessments/cycle_time_report",
    clientAssessmentRiskRatingSummary: "/c/assessments/:id/risk_rating_summary",
    clientAssessmentRiskDomainExport: "/api/client/assessments/risk_domain_export",
    clientAssessmentResponseExport: "/c/assessments/:id/response_export.:format",
    clientAssessmentAllowOnProfile: "/c/assessments/:id/allow_on_profile",
    clientAssessmentsLegacy: "/c/assessments",
    clientAssessments: "/c/vendors?tab=assessments",
    clientAssessmentsStatusReport: "/c/assessments/status_report",
    clientAssessmentsMonthlyMetrics: "/c/assessments/monthly_metrics",
    clientAssessmentsResultsReport: "/c/assessments/results_report",
    clientAssessmentsVendorAnalysisReport: "/c/assessments/vendor_analysis_report",
    clientAssessmentsVendorAnswerComparisonReport: "/c/assessments/vendor_answer_comparison_report",
    clientAssessmentsGradeExport: "/c/assessments/grade_export",
    clientAssessmentsDetailedResponseComparisonExport: "/c/assessments/detailed_response_comparison_export",
    clientVendors: "/c/vendors",
    clientVendor: "/c/vendors/:id",
    clientVendorAssessmentsStatusReport: "/c/vendors/vendor_assessments_status",
    clientVendorOfacStatusReport: "/c/vendors/ofac_status",
    clientVendorFacetSetsReport: "/c/vendors/vendor_facet_sets",
    clientVendorsBulkImportSample: "/api/client/vendors/bulk_import_sample",
    clientVendorsExport: "/c/vendor/export",
    clientVendorsContactsExport: "/c/vendors/export_contacts",
    clientVendorsCustomImportTemplate: "/c/vendors/custom_import_template",
    clientVendorResultsSummary: "/c/vendors/:id/results_summary",
    clientVendorRequestsExport: "/c/vendor_requests/export",
    clientIssues: "/c/issues",
    clientIssuesSummary: "/c/issues/summary",
    vendorAssessments:  "/v/assessments",
    vendorIssues:  "/v/issues",
    vendorProject:  "/v/projects/:id",
    assist: "/assist",
    assistQuestionSets: "/assist/question_sets",
    assistQuestionSet: "/assist/question_sets/:id",
    assistQuestionSetAnswerSets: "/assist/question_sets/:id/answer_sets",
    assistQuestionSetMatch: "/assist/question_sets/:id/match",
    assistOrganizations: "/assist/organizations",
    assistAssessments: "/assist/assessments",
    organization: "/organization",
    organizationSSO: "/organization/sso",
    organizationChoose: "/organization/choose",
    docs: "/docs",
    cmeDashboard: "/cme/dashboard",
    cmeDashboardVendors: "/cme/dashboard/vendors",
    user: "/user",
    userRegistrationEdit: "/users/edit",
    userNotifications: "/users/:id/notifications",
    usersTwoFactorAuths: "/users/two_factor_auths",
    usersLazyNotifications: "/users/lazy_notifications",
    newUserInvitation: "/users/invitation/new",
    userSessionNew: "/signin",
    userSessionDestroy: "/signout",
    userSwitchOrg: "/user/switch_org/:id",
    userStopImpersonating: "/user/stop_impersonating",
    globorgOrg: "/g/orgs/:id",
    libraryManageProfile: "/lib/manage/profile",
    libraryShare: "/lib/shares/:id",
    oauthApplicationPath: "/oauth/applications/:id",
    newOauthApplicationPath: "/oauth/applications/new",
    oauthAuthorizedApplicationPath: "/oauth/authorized_applications/:id",
    
    adminRoot: "/admin",
    announcements: "/announcements",
    announcement: "/announcements/:id",
  },
  vendor_details_options: {
    vendorContractTerm: ["Monthly", "Quarterly", "Annually"],
    vendorContractLength: ["Monthly", "Quarterly", "1 Year", "2 Years", "3 Years", "4 Years", "5 Years"],
    vendorProductsServices: ["Product (SaaS)", "Software (COTS)", "Software (Custom)", "Data Center / Facility", "Development", "Integration", "Mobile Application", "Professional Services"],
    vendorDataSourcesPii: ["Name", "Address", "Email", "Social Security Number", "Government IDs", "ePHI", "Financial Accounts or Information"],
    vendorDataSourcesCompanyData: ["Customer/Client", "Employee", "Confidential", "Financial", "Personal Information", "Health Records", "Contracts", "Intellectual Property"],
    vendorDataSourcesRecordCount: ["0 - 500K Records", "500K - 1 Million Records", "< 1 Million Records", "1- 5 Million Records", "> 5 Million Records"],
    vendorType: ["Existing Vendor (product/service)", "New Vendor (product/service)", "Currently Implemented (product/service)", "Project", "Request for Proposal (RFP)", "Incident After Action"],
    vendorContractType: ["New", "Existing", "Renewal/Additional", "Open Source", "NDA/DPA"],
    vendorSoftwareSolution: ["On-premise", "Hosted"]
  },
};

export default utils;
